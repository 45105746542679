<template>
  <div class="cg-credits-container">
    <label for="budget-entities">
      Pay with Budget entity balance

      <div v-if="showSplitPaymentInfo" class="split-payment-label">
        {{ availableBudgetEntityBalance | priceFilter }}
      </div>
    </label>

    <common-select
      v-model="selectedBudgetEntityId"
      :items="availableBudgetEntities"
      item-value="id"
      item-text="name"
      id="budget-entities"
    >
      <template #selection="{ item }">
        <div class="cg-credits-container__item" :style="`--dot-color: ${item.color}`">
          <div>{{ item.name }}</div>

          <div
            v-if="item.show_balance"
            :class="{ 'balance-insufficient': isBudgetInsufficient(item) }"
          >
            {{ (item.balance) | priceFilter }}
          </div>
        </div>
      </template>

      <template #item="{ item }">
        <div class="cg-credits-container__item" :style="`--dot-color: ${item.color}`">
          <div>{{ item.name }}</div>
          <div
            v-if="item.show_balance"
            :class="{ 'balance-insufficient': isBudgetInsufficient(item) }"
          >
            {{ (item.balance) | priceFilter }}
          </div>
        </div>
      </template>
    </common-select>

    <div
      v-if="selectedBudgetEntityMessage"
      v-html="selectedBudgetEntityMessage"
      class="cg-credits-container__message"
    />
  </div>
</template>

<script>
export default {
  name: 'CgCreditsMethod',
  props: {
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    amount: {
      type: [Number, String],
      required: false,
      default: 0
    },
    userSavedPaymentData: {
      type: Object,
      required: true,
    },
    useSplitPaymentIfAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    selectedBudgetEntityId: null,
  }),
  computed: {
    availableBudgetEntities () {
      return this.userSavedPaymentData?.budgetEntities ?? []
    },
    allowedBudgetEntities () {
      const { availableBudgetEntities } = this
      return availableBudgetEntities.filter(({ allowed }) => allowed);
    },
    defaultBudgetEntity () {
      return this.allowedBudgetEntities.find(({ is_default }) => is_default)
    },
    selectedBudgetEntityData () {
      const { selectedBudgetEntityId, availableBudgetEntities } = this

      return availableBudgetEntities
        .find((budgetEntity) => budgetEntity.id === selectedBudgetEntityId)
    },
    selectedBudgetEntityBalance () {
      return this.selectedBudgetEntityData?.balance || null
    },
    availableBudgetEntityBalance () {
      return this.selectedBudgetEntityData?.account_balance || this.selectedBudgetEntityData?.balance || null
    },
    restOfTheOrderAmount () {
      const { availableBudgetEntityBalance, amount } = this

      return Number(amount || 0) - Number(availableBudgetEntityBalance || 0)
    },
    showSplitPaymentInfo () {
      const { selectedBudgetEntityData, useSplitPaymentIfAvailable, restOfTheOrderAmount } = this

      if (selectedBudgetEntityData) {
        return useSplitPaymentIfAvailable
          && selectedBudgetEntityData?.split_payment_allowed
          && restOfTheOrderAmount > 0
      }

      return false
    },
    selectedBudgetEntityAmountIsInsufficient () {
      const { selectedBudgetEntityData, amount, showSplitPaymentInfo } = this
      if (!selectedBudgetEntityData) { return null }

      const { account_balance } = selectedBudgetEntityData
      if (account_balance === null) { return null }
      if (showSplitPaymentInfo && account_balance === null) { return null }

      return amount > account_balance
        ? `Personal balance (<span>${this.$options.filters.priceFilter(account_balance)}</span>) is insufficient for this purchase`
        : null
    },
    selectedBudgetEntityMessage () {
      const {
        showSplitPaymentInfo,
        selectedBudgetEntityData,
        selectedBudgetEntityAmountIsInsufficient,
      } = this

      const {
        message,
        account_balance
      } = selectedBudgetEntityData ?? { account_balance: null, message: null }

      if (showSplitPaymentInfo && account_balance === null) { return null }

      return selectedBudgetEntityAmountIsInsufficient || message || null
    },
  },
  watch: {
    selectedBudgetEntityData: {
      deep: true,
      handler: function (val) {
        if (val && Object.hasOwn(val, 'id')) {
          this.$emit('update:paymentData', val)
        }
      }
    },
    selectedBudgetEntityId: {
      handler: function (val) {
        if (typeof val === 'number') {
          this.$nextTick(() => {
            if (this.selectedBudgetEntityData) {
              this.$emit('update:paymentData', this.selectedBudgetEntityData)
            }
          })
        }
      }
    },
    paymentData: {
      immediate: true,
      handler: function (val) {
        const { selectedBudgetEntityData: selectedEntity } = this
        
        if (!val) {
          if (selectedEntity && Object.hasOwn(selectedEntity, 'id')) {
            this.$emit('update:paymentData', selectedEntity)
          }
        }
      }
    },
  },
  created () {
    const { paymentData } = this

    if (
      paymentData
      && (Object.hasOwn(paymentData, 'balance') || Object.hasOwn(paymentData, 'budget_id'))
    ) {
      this.selectedBudgetEntityId = paymentData?.id || paymentData?.budget_id
    } else {
      const { defaultBudgetEntity, allowedBudgetEntities, availableBudgetEntities } = this

      this.selectedBudgetEntityId = defaultBudgetEntity?.id
        || allowedBudgetEntities?.at(0)?.id
        || availableBudgetEntities?.at(0)?.id
    }
  },
  methods: {
    isBudgetInsufficient (item) {
      const { useSplitPaymentIfAvailable, amount } = this

      if (useSplitPaymentIfAvailable && item?.split_payment_allowed) { return false }
      return item.balance < Number(amount)
    },
    validate() {
      const {
        amount,
        showSplitPaymentInfo,
        selectedBudgetEntityId,
        selectedBudgetEntityData,
        selectedBudgetEntityAmountIsInsufficient,
      } = this

      const { balance } = selectedBudgetEntityData

      if (!showSplitPaymentInfo) {
        if (amount > balance) {
          this.$cgToast.error('Selected budget entity balance is insufficient')
          return false
        }
      }

      return selectedBudgetEntityId !== null && selectedBudgetEntityAmountIsInsufficient === null
    }
  },
}
</script>

<style lang="scss">
@import "../styles/split-payment.scss";

.cg-credits-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > label {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #222325;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
    gap: 8px;
    align-items: center;

    &:before {
      content: '';
      min-width: 12px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--dot-color);
    }

    & > div {
      &:nth-child(1) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:nth-child(2) {
        margin-left: auto;
        white-space: nowrap;
      }

      &.balance-insufficient {
        color: #FF5A60;
      }
    }
  }

  &__message {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;
    font-weight: 400;
    font-style: italic;

    & > span {
      color: #FF5A60;
    }
  }
}
</style>
