<template>
  <div class="cg-payment-module">
    <cg-loader v-if="loading" />

    <template v-else>
      <div
        v-if="showTopOtherPaymentsLink"
        class="cg-payment-module__other-payments-link"
        @click="showOtherPaymentMethods()"
      >
        Other payment methods
      </div>

      <component
        :is="paymentMethodComponent"
        :amount="amount"
        ref="paymentComponent"
        :userSavedPaymentData="userSavedPaymentData"
        :useSplitPaymentIfAvailable="isSplitPaymentAvailable"
        :paymentData.sync="selectedPaymentData"
        @changeSavedPayment="handleChangeSavedPayment"
      />

      <div
        v-if="showBottomOtherPaymentsLink"
        class="cg-payment-module__other-payments-link"
        @click="showOtherPaymentMethods()"
      >
        Other payment methods
      </div>

      <div
        v-if="showSecondPaymentOption && splitPaymentAmount > 0"
        class="cg-payment-module-second-method"
      >
        <label>
          We'll charge the rest to

          <div class="split-payment-label">
            {{ splitPaymentAmount | priceFilter }}
          </div>
        </label>

        <credit-card-method
          :paymentData.sync="selectedSplitPaymentData"
          @changeSavedPayment="showCreditCardPanel = true"
        />

        <credit-card-panel
          v-model="showCreditCardPanel"
          :loading="loading"
          :paymentData.sync="selectedSplitPaymentData"
          :availableCreditCards="userSavedPaymentData.creditCards"
          @onDeleteCard="deleteCreditCard"
          @onFetchData="onCreated"
        />
      </div>
    </template>

    <other-payment-methods-panel
      v-model="showOtherPaymentsPanel"
      ref="otherPaymentsPanel"
      :loading="loading"
      :availableMethods="availablePaymentMethods"
      :paymentMethod.sync="selectedPaymentMethod"
      :paymentData.sync="selectedPaymentData"
      :userSavedPaymentData="userSavedPaymentData"
      @onDeleteCard="deleteCreditCard"
      @onDeleteAch="deleteAch"
      @onFetchData="onCreated"
      @deleteBluesnapAch="deleteBluesnapAch"
      @deleteBluesnapCreditCard="deleteBluesnapCreditCard"
    />
  </div>
</template>

<script>
import Api from '@/axios/api'
import debounce from 'lodash/debounce'
import PaymentType from './paymentModule/utils/PaymentType'
import allowPoPayment from '@/mixins/allowPoPayment'
import userIsUsingBlueSnap from '@/mixins/userPaymentGateway'
import showTestPaymentMethod from '@/mixins/testPaymentMethod'

import { CgLoader } from '@corporategift/design-system'
import BluesnapCreditCardMethod from './paymentModule/selectedPayment/BluesnapCreditCardMethod.vue'
import OtherPaymentMethodsPanel from './paymentModule/ThePaymentModuleOtherPaymentsPanel.vue'
import BluesnapAchMethod from './paymentModule/selectedPayment/BluesnapAchMethod.vue'
import CreditCardPanel from './paymentModule/ThePaymentModuleCreditCardPanel.vue'
import PoPaymentMethod from './paymentModule/selectedPayment/PoPaymentMethod.vue'
import CgCreditsMethod from './paymentModule/selectedPayment/CgCreditsMethod.vue'
import CreditCardMethod from './paymentModule/selectedPayment/CreditCardMethod.vue'
import AchMethod from './paymentModule/selectedPayment/AchMethod.vue'
import TestMethod from './paymentModule/selectedPayment/TestMethod.vue'

export default {
  name: 'PaymentModule',
  mixins: [
    allowPoPayment,
    userIsUsingBlueSnap,
    showTestPaymentMethod,
  ],
  components: {
    CgLoader,
    AchMethod,
    TestMethod,
    PoPaymentMethod,
    CgCreditsMethod,
    CreditCardPanel,
    CreditCardMethod,
    BluesnapAchMethod,
    OtherPaymentMethodsPanel,
    BluesnapCreditCardMethod,
  },
  props: {
    defaultPaymentMethod: {
      type: String,
      required: false,
      validator: (val) => Object.values(PaymentType).includes(val),
      default: PaymentType.BE,
    },
    availableMethods: {
      type: Array,
      required: false,
      default: () => (Object.values(PaymentType)),
    },
    hideOtherPaymentMethods: {
      type: Boolean,
      required: false,
      default: false,
    },
    useSplitPaymentIfAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
    // BE props
    amount: {
      type: [Number, String],
      required: false,
      default: 0
    },
    payableId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    payableType: {
      type: String,
      required: false,
      default: null,
    },
    // sync props
    paymentMethod: {
      type: String,
      required: false,
      default: PaymentType.BE,
    },
    paymentData: {
      type: Object,
      required: false,
      default: null,
    },
    splitPaymentData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    initLoading: false,
    showCreditCardPanel: false,
    showOtherPaymentsPanel: false,
    budgetEntitiesController: null,
    userSavedPaymentData: {
      achs: [],
      creditCards: [],
      budgetEntities: [],
    },
  }),
  computed: {
    selectedPaymentMethod: {
      get () { return this.paymentMethod },
      set (val) { this.$emit('update:paymentMethod', val) },
    },
    selectedPaymentData: {
      get () { return this.paymentData },
      set (val) { this.$emit('update:paymentData', val) },
    },
    selectedSplitPaymentData: {
      get () { return this.splitPaymentData },
      set (val) { this.$emit('update:splitPaymentData', val) },
    },
    availablePaymentMethods () {
      const { availableMethods, allowPoPayment, userIsUsingBlueSnap } = this;
      let filteredMethods = structuredClone(availableMethods);

      if (!allowPoPayment) {
        filteredMethods = filteredMethods.filter((methodName) => methodName !== PaymentType.PO)
      }

      if (userIsUsingBlueSnap) {
        const braintreeTypes = [PaymentType.CC, PaymentType.ACH]
        filteredMethods = filteredMethods.filter((methodName) => !braintreeTypes.includes(methodName))
      } else {
        // if we'll start supporting Braintree ACH then remove PYamentType.ACH from this array
        // for now I'm adding it here to updated condition to show "Other payment methods"
        const blueSnapTypes = [PaymentType.CC_Bluesnap, PaymentType.ACH_Bluesnap, PaymentType.ACH]
        filteredMethods = filteredMethods.filter((methodName) => !blueSnapTypes.includes(methodName))
      }

      if (!this.showTestPaymentMethod) {
        filteredMethods = filteredMethods.filter((methodName) => methodName !== PaymentType.Test)
      }

      return filteredMethods
    },
    paymentMethodComponent () {
      const { selectedPaymentMethod } = this

      switch (selectedPaymentMethod) {
        case PaymentType.BE:
          return 'CgCreditsMethod';
        case PaymentType.CC:
          return 'CreditCardMethod';
        case PaymentType.PO:
          return 'PoPaymentMethod';
        case PaymentType.ACH:
          return 'AchMethod';
        case PaymentType.CC_Bluesnap:
          return 'BluesnapCreditCardMethod';
        case PaymentType.ACH_Bluesnap:
          return 'BluesnapAchMethod';
        case PaymentType.Test:
          return 'TestMethod';
        default:
          return 'CgCreditsMethod';
      }
    },
    forceHideOtherPaymentMethod () {
      const { availablePaymentMethods, hideOtherPaymentMethods } = this;

      if (hideOtherPaymentMethods) { return true }
      return availablePaymentMethods.length <= 1
    },
    showTopOtherPaymentsLink () {
      const { selectedPaymentMethod, forceHideOtherPaymentMethod } = this;

      if (forceHideOtherPaymentMethod) { return false }
      return [
        PaymentType.CC,
        PaymentType.ACH,
        PaymentType.CC_Bluesnap,
        PaymentType.ACH_Bluesnap,
      ].includes(selectedPaymentMethod)
    },
    showBottomOtherPaymentsLink () {
      const { selectedPaymentMethod, forceHideOtherPaymentMethod } = this;

      if (forceHideOtherPaymentMethod) { return false }
      return [PaymentType.BE, PaymentType.PO, PaymentType.Test].includes(selectedPaymentMethod)
    },
    isSplitPaymentAvailable () {
      const { useSplitPaymentIfAvailable, userSavedPaymentData: { creditCards } } = this
      return useSplitPaymentIfAvailable
    },
    showSecondPaymentOption () {
      const {
        useSplitPaymentIfAvailable,
        selectedPaymentMethod,
        selectedPaymentData,
        isSplitPaymentAvailable,
      } = this

      if (selectedPaymentMethod !== PaymentType.BE) { return false }
      if (!useSplitPaymentIfAvailable) { return false }
      if (!selectedPaymentData) { return false }
      if (!isSplitPaymentAvailable) { return false }

      return selectedPaymentData?.split_payment_allowed ?? false
    },
    splitPaymentAmount () {
      if (this.showSecondPaymentOption) {
        const { amount, selectedPaymentData } = this

        const selectedBalance = Number(
          selectedPaymentData?.account_balance
          || selectedPaymentData?.balance
          || 0
        )
        const parsedAmount = Number(amount)
        
        return parsedAmount - selectedBalance
      }

      return 0
    },
  },
  watch: {
    defaultPaymentMethod: {
      immediate: true,
      handler: function (val) {
        const { selectedPaymentMethod, availablePaymentMethods } = this

        if (!availablePaymentMethods.includes(val)) {
          this.selectedPaymentMethod = availablePaymentMethods.at(0);
        } else {
          if (selectedPaymentMethod !== val) {
            this.selectedPaymentMethod = val;
          }
        }
      }
    },
    selectedPaymentMethod: {
      immediate: true,
      handler: function (val) {
        const { defaultPaymentMethod, availablePaymentMethods } = this;

        if (!availablePaymentMethods.includes(val)) {
          if (availablePaymentMethods.includes(defaultPaymentMethod)) {
            this.selectedPaymentMethod = defaultPaymentMethod;
          } else {
            this.selectedPaymentMethod = availablePaymentMethods.at(0);
          }
        }
      },
    },
    selectedPaymentData: {
      immediate: true,
      handler: 'onSelectedPaymentData'
    },
    showOtherPaymentsPanel: {
      immediate: true,
      handler: function (val) {
        this.$emit('update:isPaymentPanelOpen', val)
      }
    },
    amount: 'handleAmountChange',
    showSecondPaymentOption: function (val) {
      if (val) {
        const { selectedSplitPaymentData, userSavedPaymentData: { creditCards } } = this

        if (!selectedSplitPaymentData) {
          this.selectedSplitPaymentData = creditCards.find(({ isDefault }) => isDefault) ?? creditCards.at(0)
        }
      }
    }
  },
  created () {
    this.onCreated();
  },
  beforeDestroy () {
    this.$emit('update:isPaymentPanelOpen', false)
  },
  methods: {
    onCreated () {
      const { userIsUsingBlueSnap } = this
      this.loading = true;
      const promises = [];

      if (userIsUsingBlueSnap) {
        promises.push(Api.get('/bluesnap/saved-sources'))
      } else {
        promises.push(Api.get('/braintree/saved-sources'));
      }

      promises.push(this.loadBudgetEntitiesData(true));
      this.getBraintreeDeviceData();

      this.initLoading = true;

      Promise.all(promises)
        .then(([savedSources]) => {
          if (savedSources) {
            this.userSavedPaymentData.creditCards = savedSources?.ccSource ?? [];
            this.userSavedPaymentData.achs = savedSources?.achSource ?? [];
            this.handleSavedSourcesLoad();
          }
        })
        .finally(() => {
          this.loading = false;
          this.initLoading = false;
        })
    },
    loadBudgetEntitiesData (loadingOnCreated = false) {
      const { initLoading, budgetEntitiesController, amount, payableId, payableType } = this;

      if (initLoading) { return; }

      if (budgetEntitiesController) {
        budgetEntitiesController?.abort()
        this.budgetEntitiesController = null
      }

      this.loading = true
      this.budgetEntitiesController = new AbortController();

      return Api.get('/customer/payment', {
        signal: this.budgetEntitiesController.signal,
        params: {
          amount: (+amount?.toFixed(2) || 0),
          payable_type: payableType ?? undefined,
          payable_id: payableId ?? undefined,
        }
      })
        .then(({ budgetEntities }) => {
          this.userSavedPaymentData.budgetEntities = budgetEntities
          this.budgetEntitiesController = null;
        })
        .catch((e) => {})
        .finally(() => {
          if (!this.budgetEntitiesController) {
            this.handleBudgetEntitiesData()
            this.loading = false
          }
        })
    },
    handleBudgetEntitiesData () {
      this.$nextTick(() => {
        const {
          loading,
          selectedPaymentMethod,
          userSavedPaymentData: { budgetEntities },
          availablePaymentMethods,
        } = this

        if (!loading) {
          if (selectedPaymentMethod === PaymentType.BE && !budgetEntities.length) {
            const newPaymentMethod = availablePaymentMethods.find((method) => method !== PaymentType.BE)
            this.selectedPaymentMethod = newPaymentMethod
          }
        }
      })
    },
    handleSavedSourcesLoad () {
      const { selectedPaymentMethod, selectedPaymentData } = this
      const { achs, creditCards } = this.userSavedPaymentData

      if ([PaymentType.CC, PaymentType.ACH].includes(selectedPaymentMethod) && !selectedPaymentData) {
        if (selectedPaymentMethod === PaymentType.CC) {
          if (creditCards.length) {
            const defaultCreditCard = creditCards
              .find(({ isDefault }) => isDefault) ?? creditCards.at(0)
            if (defaultCreditCard) { this.selectedPaymentData = defaultCreditCard }
          }
        } else {
          if (achs.length) {
            const defaultAch = achs.find((achItem) => achItem.default) ?? achs.at(0)
            if (defaultAch) { this.selectedPaymentData = defaultAch }
          }
        }
      } else if ([PaymentType.CC_Bluesnap, PaymentType.ACH_Bluesnap].includes(selectedPaymentMethod) && !selectedPaymentData) {
        if (selectedPaymentMethod === PaymentType.CC_Bluesnap) {
          if (creditCards.length) {
            const defaultCreditCard = creditCards
              .find(({ is_default }) => is_default) ?? creditCards.at(0)
            if (defaultCreditCard) { this.selectedPaymentData = defaultCreditCard }
          }
        } else {
          if (achs.length) {
            this.selectedPaymentData = achs.at(0)
          }
        }
      } else {
        this.onSelectedPaymentData(selectedPaymentData)
      }
    },
    onSelectedPaymentData (val) {
      const { selectedPaymentMethod } = this

      if (!val) {
        if ([PaymentType.CC, PaymentType.ACH].includes(selectedPaymentMethod)) {
          this.handleSavedSourcesLoad()
        }
      } else {
        if ([PaymentType.CC, PaymentType.ACH].includes(selectedPaymentMethod)) {
          if (Object.hasOwn(val, 'token') && Object.keys(val).length === 1) {
            const { achs, creditCards } = this.userSavedPaymentData
            if (selectedPaymentMethod === PaymentType.CC) {
              const paymentData = creditCards.find(({ token }) => token === val.token) ?? creditCards.at(0);
              if (paymentData) {
                this.selectedPaymentData = paymentData;
              }
            } else {
              const paymentData = achs.find(({ token }) => token === val.token) ?? achs.at(0);
              if (paymentData) {
                this.selectedPaymentData = paymentData;
              }
            }
          }
        }
      }
    },
    getBraintreeDeviceData () {
      const braintreeDeviceData = JSON.parse(sessionStorage.getItem('braintreeDeviceData'))
      if (braintreeDeviceData) { return }

      Api.get('/braintree/token')
        .then(({ token }) => {
          window.braintree.client.create({ authorization: token }, (err, clientInstance) => {
            if (err) {
              console.error(err);
              return;
            }

            window.braintree.dataCollector.create(
              { client: clientInstance, kount: true },
              (error, dataCollectorInstance) => {
                if (!error) {
                  sessionStorage.setItem('braintreeDeviceData', dataCollectorInstance?.deviceData)
                }
              });
          })
        })
    },
    handleAmountChange: debounce(function() {
      if (this.selectedPaymentMethod === PaymentType.BE) {
        this.loadBudgetEntitiesData()
      }
    }, 500),
    handleChangeSavedPayment (method) {
      const { userIsUsingBlueSnap } = this
      let stepToSet = 1;

      if (userIsUsingBlueSnap) {
        stepToSet = method === PaymentType.CC_Bluesnap ? 7 : 9
      } else {
        stepToSet = method === PaymentType.CC ? 2 : 4
      }

      if (this.$refs.otherPaymentsPanel) { this.$refs.otherPaymentsPanel.step = stepToSet }
      this.showOtherPaymentMethods(true);
    },
    showOtherPaymentMethods (val = true) {
      this.showOtherPaymentsPanel = val
    },
    onDeletePaymentMethod (token) {
      Api.delete(`/braintree/payment-methods/${token}`)
    },
    deleteCreditCard (token) {
      const { creditCards } = this.userSavedPaymentData

      this.onDeletePaymentMethod(token)
      const newCreditCards = creditCards.filter((creditCard) => creditCard.token !== token)

      this.userSavedPaymentData.creditCards = newCreditCards
    },
    deleteAch (token) {
      const { achs } = this.userSavedPaymentData

      this.onDeletePaymentMethod(token)
      const newAchs = achs.filter((ach) => ach.token !== token)

      this.userSavedPaymentData.achs = newAchs
    },
    //#region delete bluesnap
    deleteBluesnapCreditCard (cardFourDigits) {
      const { creditCards } = this.userSavedPaymentData
      const cardToDelete = creditCards
        .find(({ cardLastFourDigits }) => cardLastFourDigits === cardFourDigits)

      if (cardToDelete) {
        Api.delete('bluesnap/credit-cards', { data: cardToDelete })

        const newCreditCards = creditCards
          .filter(({ cardLastFourDigits }) => cardLastFourDigits !== cardFourDigits)

        this.userSavedPaymentData.creditCards = newCreditCards
      }
    },
    deleteBluesnapAch (achPublicNumber) {
      const { achs } = this.userSavedPaymentData
      const achToDelete = achs
        .find(({ publicAccountNumber }) => publicAccountNumber === achPublicNumber)

      if (achToDelete) {
        Api.delete('bluesnap/ach', { data: achToDelete })

        const newAchs = achs
          .filter(({ publicAccountNumber }) => publicAccountNumber !== achPublicNumber)

        this.userSavedPaymentData.achs = newAchs
      }
    },
    //#endregion
    validate () {
      return this.$refs?.paymentComponent?.validate()
    },
    refreshPaymentToken () {
      this.$refs?.paymentComponent?.refreshPaymentToken()
    }
  },
}
</script>

<style lang="scss" scoped>
.cg-payment-module {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 500px;

  &__other-payments-link {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #42B77A;
    cursor: pointer;
    width: fit-content;
  }
}

.cg-payment-module-second-method {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 16px;

  & > label {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #222325;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
